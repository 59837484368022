export default {
  computed: {
    authorized() {
      return this.$store.getters["account/authorized"];
    },
    user() {
      return this.$store.state.account.user;
    },
    isAdmin() {
      return true;
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("account/logout").then(() => {
        if (this.$route.meta.requiresAuth) this.$router.push({ name: "Home" });
      });
    },
 
  },
};
